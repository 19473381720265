import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _661aa977 = () => interopDefault(import('../pages/cenik/index.vue' /* webpackChunkName: "pages/cenik/index" */))
const _b5333ff0 = () => interopDefault(import('../pages/kategorie/index.vue' /* webpackChunkName: "pages/kategorie/index" */))
const _23ae6bd9 = () => interopDefault(import('../pages/pracovnici/index.vue' /* webpackChunkName: "pages/pracovnici/index" */))
const _5b921632 = () => interopDefault(import('../pages/spolecnost/index.vue' /* webpackChunkName: "pages/spolecnost/index" */))
const _56bc1f6a = () => interopDefault(import('../pages/ucet/index.vue' /* webpackChunkName: "pages/ucet/index" */))
const _b3972258 = () => interopDefault(import('../pages/zakazky/index.vue' /* webpackChunkName: "pages/zakazky/index" */))
const _58c8d5ea = () => interopDefault(import('../pages/zamestnanci/index.vue' /* webpackChunkName: "pages/zamestnanci/index" */))
const _7ba2b0a6 = () => interopDefault(import('../pages/auth/forgot-success.vue' /* webpackChunkName: "pages/auth/forgot-success" */))
const _830f1f20 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _33d8ed8d = () => interopDefault(import('../pages/pracovnici/novy.vue' /* webpackChunkName: "pages/pracovnici/novy" */))
const _1f61d6a7 = () => interopDefault(import('../pages/spolecnost/nova.vue' /* webpackChunkName: "pages/spolecnost/nova" */))
const _6006eb1a = () => interopDefault(import('../pages/zakazky/nova.vue' /* webpackChunkName: "pages/zakazky/nova" */))
const _8c9f0548 = () => interopDefault(import('../pages/zamestnanci/novy.vue' /* webpackChunkName: "pages/zamestnanci/novy" */))
const _72105ea8 = () => interopDefault(import('../pages/zakazky/upravit/fakturace.vue' /* webpackChunkName: "pages/zakazky/upravit/fakturace" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2fea9949 = () => interopDefault(import('../pages/pracovnici/upravit/_id.vue' /* webpackChunkName: "pages/pracovnici/upravit/_id" */))
const _1a80c052 = () => interopDefault(import('../pages/spolecnost/upravit/_id.vue' /* webpackChunkName: "pages/spolecnost/upravit/_id" */))
const _3fb997f8 = () => interopDefault(import('../pages/zakazky/upravit/_id.vue' /* webpackChunkName: "pages/zakazky/upravit/_id" */))
const _fd511acc = () => interopDefault(import('../pages/zamestnanci/upravit/_id.vue' /* webpackChunkName: "pages/zamestnanci/upravit/_id" */))
const _773ac4c2 = () => interopDefault(import('../pages/cenik/_id.vue' /* webpackChunkName: "pages/cenik/_id" */))
const _2f679081 = () => interopDefault(import('../pages/pracovnici/_id.vue' /* webpackChunkName: "pages/pracovnici/_id" */))
const _1609f5e2 = () => interopDefault(import('../pages/spolecnost/_id.vue' /* webpackChunkName: "pages/spolecnost/_id" */))
const _d80fd388 = () => interopDefault(import('../pages/zakazky/_id.vue' /* webpackChunkName: "pages/zakazky/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'hash',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cenik/",
    component: _661aa977,
    pathToRegexpOptions: {"strict":true},
    name: "cenik"
  }, {
    path: "/kategorie/",
    component: _b5333ff0,
    pathToRegexpOptions: {"strict":true},
    name: "kategorie"
  }, {
    path: "/pracovnici/",
    component: _23ae6bd9,
    pathToRegexpOptions: {"strict":true},
    name: "pracovnici"
  }, {
    path: "/spolecnost/",
    component: _5b921632,
    pathToRegexpOptions: {"strict":true},
    name: "spolecnost"
  }, {
    path: "/ucet/",
    component: _56bc1f6a,
    pathToRegexpOptions: {"strict":true},
    name: "ucet"
  }, {
    path: "/zakazky/",
    component: _b3972258,
    pathToRegexpOptions: {"strict":true},
    name: "zakazky"
  }, {
    path: "/zamestnanci/",
    component: _58c8d5ea,
    pathToRegexpOptions: {"strict":true},
    name: "zamestnanci"
  }, {
    path: "/auth/forgot-success/",
    component: _7ba2b0a6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-success"
  }, {
    path: "/auth/forgot/",
    component: _830f1f20,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot"
  }, {
    path: "/auth/login/",
    component: _8e1272a8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login"
  }, {
    path: "/pracovnici/novy/",
    component: _33d8ed8d,
    pathToRegexpOptions: {"strict":true},
    name: "pracovnici-novy"
  }, {
    path: "/spolecnost/nova/",
    component: _1f61d6a7,
    pathToRegexpOptions: {"strict":true},
    name: "spolecnost-nova"
  }, {
    path: "/zakazky/nova/",
    component: _6006eb1a,
    pathToRegexpOptions: {"strict":true},
    name: "zakazky-nova"
  }, {
    path: "/zamestnanci/novy/",
    component: _8c9f0548,
    pathToRegexpOptions: {"strict":true},
    name: "zamestnanci-novy"
  }, {
    path: "/zakazky/upravit/fakturace/",
    component: _72105ea8,
    pathToRegexpOptions: {"strict":true},
    name: "zakazky-upravit-fakturace"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/pracovnici/upravit/:id?/",
    component: _2fea9949,
    pathToRegexpOptions: {"strict":true},
    name: "pracovnici-upravit-id"
  }, {
    path: "/spolecnost/upravit/:id?/",
    component: _1a80c052,
    pathToRegexpOptions: {"strict":true},
    name: "spolecnost-upravit-id"
  }, {
    path: "/zakazky/upravit/:id?/",
    component: _3fb997f8,
    pathToRegexpOptions: {"strict":true},
    name: "zakazky-upravit-id"
  }, {
    path: "/zamestnanci/upravit/:id?/",
    component: _fd511acc,
    pathToRegexpOptions: {"strict":true},
    name: "zamestnanci-upravit-id"
  }, {
    path: "/cenik/:id/",
    component: _773ac4c2,
    pathToRegexpOptions: {"strict":true},
    name: "cenik-id"
  }, {
    path: "/pracovnici/:id/",
    component: _2f679081,
    pathToRegexpOptions: {"strict":true},
    name: "pracovnici-id"
  }, {
    path: "/spolecnost/:id/",
    component: _1609f5e2,
    pathToRegexpOptions: {"strict":true},
    name: "spolecnost-id"
  }, {
    path: "/zakazky/:id/",
    component: _d80fd388,
    pathToRegexpOptions: {"strict":true},
    name: "zakazky-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
