/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '/api/v1';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountsService {
  /**
   *
   */
  static accountsLoginCreate(
    params: {
      /**  */
      data: Login;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Login> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/login/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.
   */
  static accountsLogoutList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/logout/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.
   */
  static accountsLogoutCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/logout/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Calls Django Auth SetPasswordForm save method.
   */
  static accountsPasswordChangeCreate(
    params: {
      /**  */
      data: PasswordChange;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PasswordChange> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/password/change/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Calls Django Auth PasswordResetForm save method.
   */
  static accountsPasswordResetCreate(
    params: {
      /**  */
      data: CustomPasswordReset;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomPasswordReset> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/password/reset/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Password reset e-mail link is confirmed, therefore this resets the user's password.
   */
  static accountsPasswordResetConfirmCreate(
    params: {
      /**  */
      data: PasswordResetConfirm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PasswordResetConfirm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/password/reset/confirm/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
   */
  static accountsUserRead(options: IRequestOptions = {}): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/user/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
   */
  static accountsUserUpdate(
    params: {
      /**  */
      data: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/user/';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
   */
  static accountsUserPartialUpdate(
    params: {
      /**  */
      data: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/user/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CalendarService {
  /**
   *
   */
  static calendarList(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      eventStart?: string;
      /**  */
      eventEnd?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferCalendar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/calendar/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        page_size: params['pageSize'],
        event_start: params['eventStart'],
        event_end: params['eventEnd']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CardItemService {
  /**
   *
   */
  static cardItemBulkUpdateDiscount(
    params: {
      /**  */
      data: BulkUpdateRelativeDiscount;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card-item/bulk-update-discount/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardItemBulkUpdateHotelDiscount(
    params: {
      /**  */
      data: BulkUpdateRelativeDiscount;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card-item/bulk-update-hotel-discount/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CardService {
  /**
   *
   */
  static cardList(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], page_size: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardCreate(
    params: {
      /**  */
      data: Card;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateCardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Card> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardUpdate(
    params: {
      /**  */
      data: Card;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Card> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardPartialUpdate(
    params: {
      /**  */
      data: Card;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Card> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardCopy(
    params: {
      /**  */
      data: object;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CardTree> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/card/{id}/copy/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CategoryService {
  /**
   *
   */
  static categoryList(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      title?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/category/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        title: params['title'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static categoryCreate(
    params: {
      /**  */
      data: Category;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Category> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/category/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static categoryRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Category> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/category/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static categoryUpdate(
    params: {
      /**  */
      data: Category;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Category> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/category/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static categoryPartialUpdate(
    params: {
      /**  */
      data: Category;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Category> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/category/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static categoryDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/category/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CompanyService {
  /**
   *
   */
  static companyList(
    params: {
      /** Dummy help text */
      isActive?: string;
      /** Dummy help text */
      workersId?: string;
      /** A search term. */
      q?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        is_active: params['isActive'],
        workers__id: params['workersId'],
        q: params['q'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyCreate(
    params: {
      /**  */
      data: Company;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyUpdate(
    params: {
      /**  */
      data: Company;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyPartialUpdate(
    params: {
      /**  */
      data: Company;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/company/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CostCardItemService {
  /**
   *
   */
  static costCardItemList(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], page_size: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static costCardItemCreate(
    params: {
      /**  */
      data: CostCardItemWrite;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CostCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static costCardItemUserCardItems(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      offerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LatestUserCostCardItemRead[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/user_card_items/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], page_size: params['pageSize'], offer_id: params['offerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static costCardItemRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CostCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static costCardItemUpdate(
    params: {
      /**  */
      data: CostCardItemWrite;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CostCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static costCardItemPartialUpdate(
    params: {
      /**  */
      data: CostCardItemWrite;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CostCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static costCardItemDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cost-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CountryService {
  /**
   *
   */
  static countryList(
    params: {
      /** Dummy help text */
      name?: string;
      /** Dummy help text */
      isoCode?: string;
      /** A search term. */
      q?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/country/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        iso_code: params['isoCode'],
        q: params['q'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static countryRead(options: IRequestOptions = {}): Promise<Country> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/country/{id}/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class JobsService {
  /**
   *
   */
  static jobsList(
    params: {
      /** A search term. */
      q?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/jobs/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static jobsRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Job> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/jobs/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MaterialCardItemService {
  /**
   *
   */
  static materialCardItemList(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/material-card-item/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], page_size: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static materialCardItemCreate(
    params: {
      /**  */
      data: MaterialCardItemWrite;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MaterialCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/material-card-item/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static materialCardItemRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MaterialCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/material-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static materialCardItemUpdate(
    params: {
      /**  */
      data: MaterialCardItemWrite;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MaterialCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/material-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static materialCardItemPartialUpdate(
    params: {
      /**  */
      data: MaterialCardItemWrite;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MaterialCardItemRead> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/material-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static materialCardItemDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/material-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MessageService {
  /**
   *
   */
  static messageList(
    params: {
      /** Dummy help text */
      threadObjectId?: string;
      /** Dummy help text */
      parent?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/message/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        thread__object_id: params['threadObjectId'],
        parent: params['parent'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messageCreate(
    params: {
      /**  */
      data: Message;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Message> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/message/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messageRead(options: IRequestOptions = {}): Promise<Message> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/message/{id}/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messageUpdate(
    params: {
      /**  */
      data: Message;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Message> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/message/{id}/';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messagePartialUpdate(
    params: {
      /**  */
      data: Message;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Message> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/message/{id}/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messageDelete(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/message/{id}/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OfferService {
  /**
   *
   */
  static offerList(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      eventStart?: string;
      /** Dummy help text */
      eventEnd?: string;
      /** Dummy help text */
      offerState?: string;
      /** Dummy help text */
      hasInvoice?: string;
      /** Dummy help text */
      isSponsorshipEvent?: string;
      /** Dummy help text */
      eventNumberYear?: number;
      /** Dummy help text */
      eventNumber?: number;
      /** Dummy help text */
      companyName?: string;
      /** Více hodnot lze oddělit čárkami. */
      users?: string;
      /** Dummy help text */
      jobs?: string;
      /** Dummy help text */
      contact?: string;
      /** Dummy help text */
      nonActive?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        event_start: params['eventStart'],
        event_end: params['eventEnd'],
        offer_state: params['offerState'],
        has_invoice: params['hasInvoice'],
        is_sponsorship_event: params['isSponsorshipEvent'],
        event_number_year: params['eventNumberYear'],
        event_number: params['eventNumber'],
        company__name: params['companyName'],
        users: params['users'],
        jobs: params['jobs'],
        contact: params['contact'],
        non_active: params['nonActive'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerCreate(
    params: {
      /**  */
      data: Offer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Offer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerMultiplePdf(
    params: {
      /**  */
      data: MultiOfferPrint;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/multiple-pdf/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerUpdate(
    params: {
      /**  */
      data: Offer;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Offer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerPartialUpdate(
    params: {
      /**  */
      data: Offer;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Offer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerCopy(
    params: {
      /**  */
      data: object;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/copy/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerCostItems(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CostCardItemRead[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/cost-items/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerCreateInvoice(
    params: {
      /**  */
      data: OfferInvoice;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferInvoice> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/create-invoice/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerDeleteInvoice(
    params: {
      /**  */
      id: string;
      /**  */
      invoicePk: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/delete-invoice/{invoice_pk}/';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{invoice_pk}', params['invoicePk'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerDeleteOfferAttachment(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/delete-offer-attachment/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerDownloadInvoice(
    params: {
      /**  */
      id: string;
      /**  */
      invoicePk: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/download-invoice/{invoice_pk}/';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{invoice_pk}', params['invoicePk'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerExcel(
    params: {
      /**  */
      id: string;
      /**  */
      language?: string;
      /**  */
      printSet?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/excel/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], 'print-set': params['printSet'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerListInvoices(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferInvoice[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/list-invoices/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerPdf(
    params: {
      /**  */
      id: string;
      /**  */
      language?: string;
      /**  */
      printSet?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/pdf/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], 'print-set': params['printSet'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerRetrieveInvoice(
    params: {
      /**  */
      id: string;
      /**  */
      invoicePk: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferInvoice> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/retrieve-invoice/{invoice_pk}/';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{invoice_pk}', params['invoicePk'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerUpdateInvoice(
    params: {
      /**  */
      data: OfferInvoice;
      /**  */
      id: string;
      /**  */
      invoicePk: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferInvoice> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/update-invoice/{invoice_pk}/';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{invoice_pk}', params['invoicePk'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerUploadOfferAttachment(
    params: {
      /**  */
      file: any;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/upload-offer-attachment/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerValidate(
    params: {
      /**  */
      data: object;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/validate/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static offerVariant(
    params: {
      /**  */
      data: object;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offer/{id}/variant/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OtherCardItemService {
  /**
   *
   */
  static otherCardItemList(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/other-card-item/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], page_size: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otherCardItemCreate(
    params: {
      /**  */
      data: OtherCardItem;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OtherCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/other-card-item/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otherCardItemRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OtherCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/other-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otherCardItemUpdate(
    params: {
      /**  */
      data: OtherCardItem;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OtherCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/other-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otherCardItemPartialUpdate(
    params: {
      /**  */
      data: OtherCardItem;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OtherCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/other-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otherCardItemDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/other-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PersonalCardItemService {
  /**
   *
   */
  static personalCardItemList(
    params: {
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/personal-card-item/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], page_size: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalCardItemCreate(
    params: {
      /**  */
      data: PersonalCardItem;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/personal-card-item/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalCardItemRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/personal-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalCardItemUpdate(
    params: {
      /**  */
      data: PersonalCardItem;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/personal-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalCardItemPartialUpdate(
    params: {
      /**  */
      data: PersonalCardItem;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalCardItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/personal-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalCardItemDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/personal-card-item/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PricingProductService {
  /**
   *
   */
  static pricingProductList(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      categoryId?: string;
      /** Dummy help text */
      deleted?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        category__id: params['categoryId'],
        deleted: params['deleted'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductCreate(
    params: {
      /**  */
      data: PricingProduct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PricingProduct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductExcel(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      categoryId?: string;
      /** Dummy help text */
      deleted?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      language?: string;
      /**  */
      companyId?: string;
      /**  */
      surcharge?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/excel/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        category__id: params['categoryId'],
        deleted: params['deleted'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize'],
        language: params['language'],
        company_id: params['companyId'],
        surcharge: params['surcharge']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductPdf(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      categoryId?: string;
      /** Dummy help text */
      deleted?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      language?: string;
      /**  */
      companyId?: string;
      /**  */
      surcharge?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/pdf/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        category__id: params['categoryId'],
        deleted: params['deleted'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize'],
        language: params['language'],
        company_id: params['companyId'],
        surcharge: params['surcharge']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PricingProduct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductUpdate(
    params: {
      /**  */
      data: PricingProduct;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PricingProduct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductPartialUpdate(
    params: {
      /**  */
      data: PricingProduct;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PricingProduct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static pricingProductDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pricing-product/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static userList(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      jobType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        job_type: params['jobType'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userCreate(
    params: {
      /**  */
      data: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userSelfRead(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      jobType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/self/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        job_type: params['jobType'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userSelfPartialUpdate(
    params: {
      /**  */
      data: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/self/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userUpdate(
    params: {
      /**  */
      data: User;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userPartialUpdate(
    params: {
      /**  */
      data: User;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WorkerService {
  /**
   *
   */
  static workerList(
    params: {
      /** A search term. */
      q?: string;
      /** Dummy help text */
      isActive?: string;
      /** Dummy help text */
      companyId?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worker/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        q: params['q'],
        is_active: params['isActive'],
        company__id: params['companyId'],
        ordering: params['ordering'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workerCreate(
    params: {
      /**  */
      data: Worker;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Worker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worker/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workerRead(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Worker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workerUpdate(
    params: {
      /**  */
      data: Worker;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Worker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workerPartialUpdate(
    params: {
      /**  */
      data: Worker;
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Worker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['data'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workerDelete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class Login {
  /**  */
  'username'?: string;

  /**  */
  'email'?: string;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['username'] = data['username'];
    this['email'] = data['email'];
    this['password'] = data['password'];
  }
}

export class PasswordChange {
  /**  */
  'new_password1': string;

  /**  */
  'new_password2': string;

  constructor(data: undefined | any = {}) {
    this['new_password1'] = data['new_password1'];
    this['new_password2'] = data['new_password2'];
  }
}

export class CustomPasswordReset {
  /**  */
  'email': string;

  constructor(data: undefined | any = {}) {
    this['email'] = data['email'];
  }
}

export class PasswordResetConfirm {
  /**  */
  'new_password1': string;

  /**  */
  'new_password2': string;

  /**  */
  'uid': string;

  /**  */
  'token': string;

  constructor(data: undefined | any = {}) {
    this['new_password1'] = data['new_password1'];
    this['new_password2'] = data['new_password2'];
    this['uid'] = data['uid'];
    this['token'] = data['token'];
  }
}

export class Job {
  /**  */
  'id': string;

  /**  */
  'name': string;

  /**  */
  'send_status_notification'?: boolean;

  /**  */
  'send_worker_notification'?: boolean;

  /**  */
  'show_in_active_work_list'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['send_status_notification'] = data['send_status_notification'];
    this['send_worker_notification'] = data['send_worker_notification'];
    this['show_in_active_work_list'] = data['show_in_active_work_list'];
  }
}

export class User {
  /**  */
  'id'?: string;

  /**  */
  'first_name': string;

  /**  */
  'last_name': string;

  /**  */
  'email': string;

  /**  */
  'phone': string;

  /**  */
  'mobile_phone': string;

  /**  */
  'description': string;

  /**  */
  'job_types'?: string[];

  /**  */
  'job_types_obj'?: Job[];

  /**  */
  'is_admin'?: boolean;

  /**  */
  'hour_rate'?: string;

  /**  */
  'show_in_input'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['first_name'] = data['first_name'];
    this['last_name'] = data['last_name'];
    this['email'] = data['email'];
    this['phone'] = data['phone'];
    this['mobile_phone'] = data['mobile_phone'];
    this['description'] = data['description'];
    this['job_types'] = data['job_types'];
    this['job_types_obj'] = data['job_types_obj'];
    this['is_admin'] = data['is_admin'];
    this['hour_rate'] = data['hour_rate'];
    this['show_in_input'] = data['show_in_input'];
  }
}

export class OfferCalendar {
  /**  */
  'event_title': string;

  /**  */
  'event_start': Date;

  /**  */
  'event_end': Date;

  /**  */
  'offer_state': EnumOfferCalendarOfferState;

  constructor(data: undefined | any = {}) {
    this['event_title'] = data['event_title'];
    this['event_start'] = data['event_start'];
    this['event_end'] = data['event_end'];
    this['offer_state'] = data['offer_state'];
  }
}

export class BulkUpdateRelativeDiscount {
  /**  */
  'ids': string[];

  /**  */
  'relative_discount': string;

  constructor(data: undefined | any = {}) {
    this['ids'] = data['ids'];
    this['relative_discount'] = data['relative_discount'];
  }
}

export class Category {
  /**  */
  'id'?: string;

  /**  */
  'title': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['title'] = data['title'];
  }
}

export class PricingProductTree {
  /**  */
  'id'?: string;

  /**  */
  'category': Category;

  /**  */
  'category__title'?: string;

  /**  */
  'title': string;

  /**  */
  'title_en': string;

  /**  */
  'price': string;

  /**  */
  'description': string;

  /**  */
  'is_reservation_disabled': boolean;

  /**  */
  'deleted': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['category'] = data['category'];
    this['category__title'] = data['category__title'];
    this['title'] = data['title'];
    this['title_en'] = data['title_en'];
    this['price'] = data['price'];
    this['description'] = data['description'];
    this['is_reservation_disabled'] = data['is_reservation_disabled'];
    this['deleted'] = data['deleted'];
  }
}

export class GeneralCardItem {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum': string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time': string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'card': string;

  /**  */
  'pricing_product': PricingProductTree;

  /**  */
  'text': string;

  /**  */
  'price': string;

  /**  */
  'start_time'?: Date;

  /**  */
  'end_time'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['card'] = data['card'];
    this['pricing_product'] = data['pricing_product'];
    this['text'] = data['text'];
    this['price'] = data['price'];
    this['start_time'] = data['start_time'];
    this['end_time'] = data['end_time'];
  }
}

export class Card {
  /**  */
  'id'?: string;

  /**  */
  'parent': string;

  /**  */
  'title': string;

  /**  */
  'card_type': EnumCardCardType;

  /**  */
  'price_sum'?: string;

  /**  */
  'discount_amount_sum'?: string;

  /**  */
  'hotel_discount_amount_sum'?: string;

  /**  */
  'discount_percentage'?: string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'sort_order': number;

  /**  */
  'card_items'?: GeneralCardItem[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['parent'] = data['parent'];
    this['title'] = data['title'];
    this['card_type'] = data['card_type'];
    this['price_sum'] = data['price_sum'];
    this['discount_amount_sum'] = data['discount_amount_sum'];
    this['hotel_discount_amount_sum'] = data['hotel_discount_amount_sum'];
    this['discount_percentage'] = data['discount_percentage'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['sort_order'] = data['sort_order'];
    this['card_items'] = data['card_items'];
  }
}

export class CreateCardResponseChildren {
  /**  */
  'id'?: string;

  /**  */
  'parent': string;

  /**  */
  'title': string;

  /**  */
  'card_type': EnumCreateCardResponseChildrenCardType;

  /**  */
  'price_sum'?: string;

  /**  */
  'discount_amount_sum'?: string;

  /**  */
  'hotel_discount_amount_sum'?: string;

  /**  */
  'discount_percentage'?: string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'sort_order': number;

  /**  */
  'card_items'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['parent'] = data['parent'];
    this['title'] = data['title'];
    this['card_type'] = data['card_type'];
    this['price_sum'] = data['price_sum'];
    this['discount_amount_sum'] = data['discount_amount_sum'];
    this['hotel_discount_amount_sum'] = data['hotel_discount_amount_sum'];
    this['discount_percentage'] = data['discount_percentage'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['sort_order'] = data['sort_order'];
    this['card_items'] = data['card_items'];
  }
}

export class CreateCardResponse {
  /**  */
  'id'?: string;

  /**  */
  'parent': string;

  /**  */
  'title': string;

  /**  */
  'card_type': EnumCreateCardResponseCardType;

  /**  */
  'price_sum'?: string;

  /**  */
  'discount_amount_sum'?: string;

  /**  */
  'hotel_discount_amount_sum'?: string;

  /**  */
  'discount_percentage'?: string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'sort_order': number;

  /**  */
  'card_items'?: string;

  /**  */
  'children': CreateCardResponseChildren[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['parent'] = data['parent'];
    this['title'] = data['title'];
    this['card_type'] = data['card_type'];
    this['price_sum'] = data['price_sum'];
    this['discount_amount_sum'] = data['discount_amount_sum'];
    this['hotel_discount_amount_sum'] = data['hotel_discount_amount_sum'];
    this['discount_percentage'] = data['discount_percentage'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['sort_order'] = data['sort_order'];
    this['card_items'] = data['card_items'];
    this['children'] = data['children'];
  }
}

export class CardTree {
  /**  */
  'card': Card;

  /**  */
  'children': CardTree[];

  constructor(data: undefined | any = {}) {
    this['card'] = data['card'];
    this['children'] = data['children'];
  }
}

export class Country {
  /**  */
  'id'?: string;

  /**  */
  'name': string;

  /**  */
  'iso_code': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['iso_code'] = data['iso_code'];
  }
}

export class Company {
  /**  */
  'id'?: string;

  /**  */
  'name': string;

  /**  */
  'responsible_person': string;

  /**  */
  'dealer': string;

  /**  */
  'phone_number': string;

  /**  */
  'phone_number2': string;

  /**  */
  'mobile_phone_number': string;

  /**  */
  'email': string;

  /**  */
  'reg_number': string;

  /**  */
  'vat_number': string;

  /**  */
  'website': string;

  /**  */
  'description': string;

  /**  */
  'surcharge': string;

  /**  */
  'is_active': boolean;

  /**  */
  'street': string;

  /**  */
  'city': string;

  /**  */
  'zip': string;

  /**  */
  'country_data'?: Country;

  /**  */
  'country'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['responsible_person'] = data['responsible_person'];
    this['dealer'] = data['dealer'];
    this['phone_number'] = data['phone_number'];
    this['phone_number2'] = data['phone_number2'];
    this['mobile_phone_number'] = data['mobile_phone_number'];
    this['email'] = data['email'];
    this['reg_number'] = data['reg_number'];
    this['vat_number'] = data['vat_number'];
    this['website'] = data['website'];
    this['description'] = data['description'];
    this['surcharge'] = data['surcharge'];
    this['is_active'] = data['is_active'];
    this['street'] = data['street'];
    this['city'] = data['city'];
    this['zip'] = data['zip'];
    this['country_data'] = data['country_data'];
    this['country'] = data['country'];
  }
}

export class CostCardItemRead {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time'?: string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'card': string;

  /**  */
  'pricing_product': PricingProductTree;

  /**  */
  'price': string;

  /**  */
  'start_time'?: Date;

  /**  */
  'end_time'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['card'] = data['card'];
    this['pricing_product'] = data['pricing_product'];
    this['price'] = data['price'];
    this['start_time'] = data['start_time'];
    this['end_time'] = data['end_time'];
  }
}

export class CostCardItemWrite {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time': string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'card': string;

  /**  */
  'pricing_product'?: string;

  /**  */
  'start_time'?: Date;

  /**  */
  'end_time'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['card'] = data['card'];
    this['pricing_product'] = data['pricing_product'];
    this['start_time'] = data['start_time'];
    this['end_time'] = data['end_time'];
  }
}

export class OfferLatestUserCostCardItem {
  /**  */
  'id'?: string;

  /**  */
  'event_number'?: string;

  /**  */
  'event_title'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['event_number'] = data['event_number'];
    this['event_title'] = data['event_title'];
  }
}

export class LatestUserCostCardItemRead {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time'?: string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'card': string;

  /**  */
  'pricing_product': PricingProductTree;

  /**  */
  'price': string;

  /**  */
  'start_time'?: Date;

  /**  */
  'end_time'?: Date;

  /**  */
  'offer'?: OfferLatestUserCostCardItem;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['card'] = data['card'];
    this['pricing_product'] = data['pricing_product'];
    this['price'] = data['price'];
    this['start_time'] = data['start_time'];
    this['end_time'] = data['end_time'];
    this['offer'] = data['offer'];
  }
}

export class MaterialCardItemRead {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time': string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'card': string;

  /**  */
  'price': string;

  /**  */
  'pricing_product': PricingProductTree;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['card'] = data['card'];
    this['price'] = data['price'];
    this['pricing_product'] = data['pricing_product'];
  }
}

export class MaterialCardItemWrite {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time': string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'card': string;

  /**  */
  'price': string;

  /**  */
  'pricing_product': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['card'] = data['card'];
    this['price'] = data['price'];
    this['pricing_product'] = data['pricing_product'];
  }
}

export class Message {
  /**  */
  'id'?: number;

  /**  */
  'owner'?: User;

  /**  */
  'thread_ct_id': string;

  /**  */
  'text': string;

  /**  */
  'parent'?: number;

  /**  */
  'created_at'?: Date;

  /**  */
  'updated_at'?: Date;

  /**  */
  'replies'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['owner'] = data['owner'];
    this['thread_ct_id'] = data['thread_ct_id'];
    this['text'] = data['text'];
    this['parent'] = data['parent'];
    this['created_at'] = data['created_at'];
    this['updated_at'] = data['updated_at'];
    this['replies'] = data['replies'];
  }
}

export class SimpleUser {
  /**  */
  'id'?: string;

  /**  */
  'first_name': string;

  /**  */
  'last_name': string;

  /**  */
  'email': string;

  /**  */
  'phone'?: string;

  /**  */
  'mobile_phone'?: string;

  /**  */
  'is_active'?: boolean;

  /**  */
  'is_admin'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['first_name'] = data['first_name'];
    this['last_name'] = data['last_name'];
    this['email'] = data['email'];
    this['phone'] = data['phone'];
    this['mobile_phone'] = data['mobile_phone'];
    this['is_active'] = data['is_active'];
    this['is_admin'] = data['is_admin'];
  }
}

export class OfferUser {
  /**  */
  'id'?: string;

  /**  */
  'user': string;

  /**  */
  'job': string;

  /**  */
  'active': boolean;

  /**  */
  'user_obj'?: SimpleUser;

  /**  */
  'job_obj'?: Job;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['user'] = data['user'];
    this['job'] = data['job'];
    this['active'] = data['active'];
    this['user_obj'] = data['user_obj'];
    this['job_obj'] = data['job_obj'];
  }
}

export class OfferAttachment {
  /**  */
  'file_name'?: string;

  /**  */
  'url'?: string;

  constructor(data: undefined | any = {}) {
    this['file_name'] = data['file_name'];
    this['url'] = data['url'];
  }
}

export class Offer {
  /**  */
  'id'?: string;

  /**  */
  'offer_users'?: OfferUser[];

  /**  */
  'company': string;

  /**  */
  'company_data'?: Company;

  /**  */
  'created_by': string;

  /**  */
  'root_card'?: string;

  /**  */
  'contact': string;

  /**  */
  'offer_state': EnumOfferOfferState;

  /**  */
  'all_offer_states'?: string;

  /**  */
  'is_sponsorship_event': boolean;

  /**  */
  'has_invoice': boolean;

  /**  */
  'validity': Date;

  /**  */
  'installation_date': Date;

  /**  */
  'event_number'?: string;

  /**  */
  'event_number_custom_text': string;

  /**  */
  'event_title': string;

  /**  */
  'event_start': Date;

  /**  */
  'event_end': Date;

  /**  */
  'deadline'?: Date;

  /**  */
  'event_address': string;

  /**  */
  'price_without_vat'?: string;

  /**  */
  'discount_amount_sum'?: string;

  /**  */
  'discount_percentage'?: string;

  /**  */
  'discount': string;

  /**  */
  'currency': EnumOfferCurrency;

  /**  */
  'custom_exchange_rate': string;

  /**  */
  'vat_rate'?: string;

  /**  */
  'denial_reason': string;

  /**  */
  'note': string;

  /**  */
  'title_above_cards': string;

  /**  */
  'last_message'?: Message;

  /**  */
  'comment'?: string;

  /**  */
  'offer_attachments'?: OfferAttachment[];

  /**  */
  'attachment_title'?: string;

  /**  */
  'contact_full_name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['offer_users'] = data['offer_users'];
    this['company'] = data['company'];
    this['company_data'] = data['company_data'];
    this['created_by'] = data['created_by'];
    this['root_card'] = data['root_card'];
    this['contact'] = data['contact'];
    this['offer_state'] = data['offer_state'];
    this['all_offer_states'] = data['all_offer_states'];
    this['is_sponsorship_event'] = data['is_sponsorship_event'];
    this['has_invoice'] = data['has_invoice'];
    this['validity'] = data['validity'];
    this['installation_date'] = data['installation_date'];
    this['event_number'] = data['event_number'];
    this['event_number_custom_text'] = data['event_number_custom_text'];
    this['event_title'] = data['event_title'];
    this['event_start'] = data['event_start'];
    this['event_end'] = data['event_end'];
    this['deadline'] = data['deadline'];
    this['event_address'] = data['event_address'];
    this['price_without_vat'] = data['price_without_vat'];
    this['discount_amount_sum'] = data['discount_amount_sum'];
    this['discount_percentage'] = data['discount_percentage'];
    this['discount'] = data['discount'];
    this['currency'] = data['currency'];
    this['custom_exchange_rate'] = data['custom_exchange_rate'];
    this['vat_rate'] = data['vat_rate'];
    this['denial_reason'] = data['denial_reason'];
    this['note'] = data['note'];
    this['title_above_cards'] = data['title_above_cards'];
    this['last_message'] = data['last_message'];
    this['comment'] = data['comment'];
    this['offer_attachments'] = data['offer_attachments'];
    this['attachment_title'] = data['attachment_title'];
    this['contact_full_name'] = data['contact_full_name'];
  }
}

export class MultiOfferPrint {
  /**  */
  'offer_ids': string[];

  /**  */
  'language': EnumMultiOfferPrintLanguage;

  /**  */
  'print_set': IMultiOfferPrintPrintSet;

  constructor(data: undefined | any = {}) {
    this['offer_ids'] = data['offer_ids'];
    this['language'] = data['language'];
    this['print_set'] = data['print_set'];
  }
}

export class OfferDetail {
  /**  */
  'id'?: string;

  /**  */
  'offer_users'?: OfferUser[];

  /**  */
  'company': string;

  /**  */
  'company_data'?: Company;

  /**  */
  'created_by': string;

  /**  */
  'root_card'?: string;

  /**  */
  'contact': string;

  /**  */
  'offer_state': EnumOfferDetailOfferState;

  /**  */
  'all_offer_states'?: string;

  /**  */
  'is_sponsorship_event': boolean;

  /**  */
  'has_invoice': boolean;

  /**  */
  'validity': Date;

  /**  */
  'installation_date': Date;

  /**  */
  'event_number'?: string;

  /**  */
  'event_number_custom_text': string;

  /**  */
  'event_title': string;

  /**  */
  'event_start': Date;

  /**  */
  'event_end': Date;

  /**  */
  'deadline'?: Date;

  /**  */
  'event_address': string;

  /**  */
  'price_without_vat'?: string;

  /**  */
  'discount_amount_sum'?: string;

  /**  */
  'discount_percentage'?: string;

  /**  */
  'discount': string;

  /**  */
  'currency': EnumOfferDetailCurrency;

  /**  */
  'custom_exchange_rate': string;

  /**  */
  'vat_rate'?: string;

  /**  */
  'denial_reason': string;

  /**  */
  'note': string;

  /**  */
  'title_above_cards': string;

  /**  */
  'last_message'?: Message;

  /**  */
  'comment'?: string;

  /**  */
  'offer_attachments'?: OfferAttachment[];

  /**  */
  'attachment_title'?: string;

  /**  */
  'contact_full_name'?: string;

  /**  */
  'cards': CardTree[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['offer_users'] = data['offer_users'];
    this['company'] = data['company'];
    this['company_data'] = data['company_data'];
    this['created_by'] = data['created_by'];
    this['root_card'] = data['root_card'];
    this['contact'] = data['contact'];
    this['offer_state'] = data['offer_state'];
    this['all_offer_states'] = data['all_offer_states'];
    this['is_sponsorship_event'] = data['is_sponsorship_event'];
    this['has_invoice'] = data['has_invoice'];
    this['validity'] = data['validity'];
    this['installation_date'] = data['installation_date'];
    this['event_number'] = data['event_number'];
    this['event_number_custom_text'] = data['event_number_custom_text'];
    this['event_title'] = data['event_title'];
    this['event_start'] = data['event_start'];
    this['event_end'] = data['event_end'];
    this['deadline'] = data['deadline'];
    this['event_address'] = data['event_address'];
    this['price_without_vat'] = data['price_without_vat'];
    this['discount_amount_sum'] = data['discount_amount_sum'];
    this['discount_percentage'] = data['discount_percentage'];
    this['discount'] = data['discount'];
    this['currency'] = data['currency'];
    this['custom_exchange_rate'] = data['custom_exchange_rate'];
    this['vat_rate'] = data['vat_rate'];
    this['denial_reason'] = data['denial_reason'];
    this['note'] = data['note'];
    this['title_above_cards'] = data['title_above_cards'];
    this['last_message'] = data['last_message'];
    this['comment'] = data['comment'];
    this['offer_attachments'] = data['offer_attachments'];
    this['attachment_title'] = data['attachment_title'];
    this['contact_full_name'] = data['contact_full_name'];
    this['cards'] = data['cards'];
  }
}

export class OfferInvoice {
  /**  */
  'id'?: string;

  /**  */
  'offer'?: string;

  /**  */
  'project_owner': string;

  /**  */
  'offer_name': string;

  /**  */
  'company_data'?: Company;

  /**  */
  'issued_on'?: Date;

  /**  */
  'due'?: number;

  /**  */
  'pdf_url'?: string;

  /**  */
  'custom_id'?: string;

  /**  */
  'cost_items'?: string[];

  /**  */
  'cost_items_data'?: CostCardItemRead[];

  /**  */
  'attachments'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['offer'] = data['offer'];
    this['project_owner'] = data['project_owner'];
    this['offer_name'] = data['offer_name'];
    this['company_data'] = data['company_data'];
    this['issued_on'] = data['issued_on'];
    this['due'] = data['due'];
    this['pdf_url'] = data['pdf_url'];
    this['custom_id'] = data['custom_id'];
    this['cost_items'] = data['cost_items'];
    this['cost_items_data'] = data['cost_items_data'];
    this['attachments'] = data['attachments'];
  }
}

export class OtherCardItem {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'price': string;

  /**  */
  'relative_discount': string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'text': string;

  /**  */
  'card': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['price'] = data['price'];
    this['relative_discount'] = data['relative_discount'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['text'] = data['text'];
    this['card'] = data['card'];
  }
}

export class PersonalCardItem {
  /**  */
  'id'?: string;

  /**  */
  'note': string;

  /**  */
  'relative_discount': string;

  /**  */
  'hotel_discount_percentage'?: string;

  /**  */
  'hotel_price_sum'?: string;

  /**  */
  'hotel_discount_amount'?: string;

  /**  */
  'price_sum'?: string;

  /**  */
  'relative_discount_amount'?: string;

  /**  */
  'highlight': boolean;

  /**  */
  'sort_order': number;

  /**  */
  'quantity': string;

  /**  */
  'amount_of_time': string;

  /**  */
  'created_at'?: Date;

  /**  */
  'created_by_user'?: User;

  /**  */
  'text': string;

  /**  */
  'card': string;

  /**  */
  'price': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['note'] = data['note'];
    this['relative_discount'] = data['relative_discount'];
    this['hotel_discount_percentage'] = data['hotel_discount_percentage'];
    this['hotel_price_sum'] = data['hotel_price_sum'];
    this['hotel_discount_amount'] = data['hotel_discount_amount'];
    this['price_sum'] = data['price_sum'];
    this['relative_discount_amount'] = data['relative_discount_amount'];
    this['highlight'] = data['highlight'];
    this['sort_order'] = data['sort_order'];
    this['quantity'] = data['quantity'];
    this['amount_of_time'] = data['amount_of_time'];
    this['created_at'] = data['created_at'];
    this['created_by_user'] = data['created_by_user'];
    this['text'] = data['text'];
    this['card'] = data['card'];
    this['price'] = data['price'];
  }
}

export class PricingProduct {
  /**  */
  'id'?: string;

  /**  */
  'category'?: string;

  /**  */
  'category__title'?: string;

  /**  */
  'title': string;

  /**  */
  'title_en': string;

  /**  */
  'price': string;

  /**  */
  'description': string;

  /**  */
  'is_reservation_disabled': boolean;

  /**  */
  'deleted': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['category'] = data['category'];
    this['category__title'] = data['category__title'];
    this['title'] = data['title'];
    this['title_en'] = data['title_en'];
    this['price'] = data['price'];
    this['description'] = data['description'];
    this['is_reservation_disabled'] = data['is_reservation_disabled'];
    this['deleted'] = data['deleted'];
  }
}

export class Worker {
  /**  */
  'id'?: string;

  /**  */
  'first_name': string;

  /**  */
  'last_name': string;

  /**  */
  'company': string[];

  /**  */
  'job': string;

  /**  */
  'phone_number': string;

  /**  */
  'mobile_phone_number': string;

  /**  */
  'email': string;

  /**  */
  'description': string;

  /**  */
  'is_active': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['first_name'] = data['first_name'];
    this['last_name'] = data['last_name'];
    this['company'] = data['company'];
    this['job'] = data['job'];
    this['phone_number'] = data['phone_number'];
    this['mobile_phone_number'] = data['mobile_phone_number'];
    this['email'] = data['email'];
    this['description'] = data['description'];
    this['is_active'] = data['is_active'];
  }
}
export enum EnumOfferCalendarOfferState {
  'offer' = 'offer',
  'reserved' = 'reserved',
  'rejected' = 'rejected',
  'ended' = 'ended'
}
export enum EnumCardCardType {
  'material' = 'material',
  'personal' = 'personal',
  'place' = 'place',
  'root' = 'root',
  'other' = 'other',
  'cost' = 'cost'
}
export enum EnumCreateCardResponseChildrenCardType {
  'material' = 'material',
  'personal' = 'personal',
  'place' = 'place',
  'root' = 'root',
  'other' = 'other',
  'cost' = 'cost'
}
export enum EnumCreateCardResponseCardType {
  'material' = 'material',
  'personal' = 'personal',
  'place' = 'place',
  'root' = 'root',
  'other' = 'other',
  'cost' = 'cost'
}
export enum EnumOfferOfferState {
  'offer' = 'offer',
  'reserved' = 'reserved',
  'rejected' = 'rejected',
  'ended' = 'ended'
}
export enum EnumOfferCurrency {
  'CZK' = 'CZK',
  'EUR' = 'EUR',
  'USD' = 'USD',
  'GBP' = 'GBP'
}
export enum EnumMultiOfferPrintLanguage {
  'cs' = 'cs',
  'en' = 'en'
}
type IMultiOfferPrintPrintSet = 1 | 6 | 2 | 3 | 4 | 5;
export enum EnumOfferDetailOfferState {
  'offer' = 'offer',
  'reserved' = 'reserved',
  'rejected' = 'rejected',
  'ended' = 'ended'
}
export enum EnumOfferDetailCurrency {
  'CZK' = 'CZK',
  'EUR' = 'EUR',
  'USD' = 'USD',
  'GBP' = 'GBP'
}
