import { Offer, OfferDetail, OfferInvoice } from '~/service/api'
import { CARD_STATE_OFFER } from '~/helpers/constants'

export class CustomOffer extends Offer {
  invoices?: OfferInvoice[]
  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.company = data.company || null
    this.contact = data.contact || null
    this.created_by = data.created_by || null
    this.root_card = data.root_card || null
    this.company = data.company || null
    this.offer_state = data.offer_state || CARD_STATE_OFFER
    this.is_sponsorship_event = data.is_sponsorship_event || false
    this.has_invoice = data.has_invoice || false
    this.validity = data.validity || null
    this.installation_date = data.installation_date || null
    this.event_number = data.event_number || null
    this.event_number_custom_text = data.event_number_custom_text || ''
    this.event_title = data.event_title || null
    this.event_start = data.event_start || null
    this.event_end = data.event_end || null
    this.deadline = data.deadline || null
    this.event_address = data.event_address || null
    this.price_without_vat = data.price_without_vat || null
    this.currency = data.currency || null
    this.custom_exchange_rate = data.custom_exchange_rate || null
    this.denial_reason = data.denial_reason || null
    this.note = data.note || null
    this.vat_rate = data.vat_rate || '21.0'
    this.discount = data.discount || null
    this.title_above_cards = data.title_above_cards || 'Titulek akce'
    this.offer_users = data.offer_users || null
    this.attachment_title = data.attachment_title || null
    this.invoices = data.invoices || []
  }
}

export class CustomOfferDetail extends OfferDetail {
  invoices?: OfferInvoice[]
  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.company = data.company || null
    this.created_by = data.created_by || null
    this.root_card = data.root_card || null
    this.company = data.company || null
    this.offer_state = data.offer_state || CARD_STATE_OFFER
    this.is_sponsorship_event = data.is_sponsorship_event || false
    this.has_invoice = data.has_invoice || false
    this.validity = data.validity || null
    this.installation_date = data.installation_date || ''
    this.event_number = data.event_number || null
    this.event_number_custom_text = data.event_number_custom_text || ''
    this.event_title = data.event_title || null
    this.event_start = data.event_start || null
    this.event_end = data.event_end || null
    this.event_address = data.event_address || null
    this.price_without_vat = data.price_without_vat || null
    this.currency = data.currency || null
    this.custom_exchange_rate = data.custom_exchange_rate || null
    this.denial_reason = data.denial_reason || null
    this.note = data.note || null
    this.vat_rate = data.vat_rate || '0.0'
    this.discount = data.discount || null
    this.cards = data.cards || []
    this.title_above_cards = data.title_above_cards || 'Titulek akce'
    this.offer_users = data.offer_users || null
    this.attachment_title = data.attachment_title || null
    this.invoices = data.invoices || []
  }
}
